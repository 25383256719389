/**
 * 工作任务作业指导
 * @type {{}}
 */
import Vue from "vue";
import { Input, Icon, Select, Option, Message, Table, Page, Modal,Button,    Dropdown,
    DropdownMenu, DropdownItem,Poptip } from "view-design";
import { taskListColumns,
    taskStatus,
    getTaskListService,
    getTaskClassAllService,
} from '@/service/workflow-service'
import { mapMutations,mapState } from "vuex";
Vue.component('Input', Input);
Vue.component('Icon', Icon);
Vue.component('Select', Select);
Vue.component('Option', Option);
Vue.component('Table', Table);
Vue.component('Page', Page);
Vue.component('Modal', Modal);
Vue.component('Button', Button);
Vue.component('Dropdown', Dropdown);
Vue.component('DropdownMenu', DropdownMenu);
Vue.component('DropdownItem', DropdownItem);
Vue.component('Poptip', Poptip);
import vueQr from "vue-qr";
import { saveCode } from '@/utils/tools'
import html2canvas from 'html2canvas'
import defaultAvatar from '@/assets/Image/default-avatar.png'
const TaskGuidanceList = {
    name:'TaskGuidanceList',
    components:{ vueQr },
    computed: {
        ...mapState({
            taskTypeId: state => state.system.taskTypeId, //分类id
        }),
    },
    data() {
        return {
            taskLoading:false, //loading
            taskListColumns:taskListColumns, //列表显示字段
            defaultAvatar:defaultAvatar,
            taskListArray:{}, //列表数据
            taskStatus:taskStatus, //任务状态
            taskModalVisible:false, //新增/编辑弹框状态
            editLoading:false, //编辑loading
            selectAllCancel:[], //全选时的临时存储
            workflowListArray:[], //工作流列表
            btnStatus:false, //按钮状态
            qrCodeVisible:false, //二维码modal状态
            QRCode:'',
            tasksName:'',
            logoSrc:require("@/assets/Icon/logo-img.png"),
            deviceListHeight:0, //表格高度
            taskEditParams:{
                tasksName:'',//任务名称
                flowId:'', //工作流id
                id:'',
                status:'', //发布状态
            },
            taskListParams:{ //提交的数据集
                tasksName:'',  //任务名称
                flowName:'', //引用流程
                status:'', //任务状态
                classificationId:'',
                currentPage: 1,  //当前页
                displayNumber:10 // 显示条数
            },
            deviceListArray:[],
        }
    },
    created() {
        this.onTaskList().then()
        this.onTableHeight()
        this.onTaskClassAllService().then()
    },
    mounted() {
        window.addEventListener('resize', this.onTableHeight)
    },
    methods: {
        ...mapMutations('system',['SET_FLOW_ID','SET_TASK_ID','SET_TASK_LIST','SET_TASK_TYPE_ID']),
        /**
         * 计算table高度
         */
        onTableHeight(){
            this.deviceListHeight = window.innerHeight - 223
            this.windowWidth = window.innerWidth
        },
        /**
         * 重置
         */
        onReset() {
            this.taskListParams.tasksName = ''
            this.taskListParams.flowName = ''
            this.taskListParams.status = ''
        },
        /**
         * 搜索
         */
        onSearch(){
            this.onTaskList().then()
        },
        /**
         * 跳转携带id
         * @param id
         */
        onLinks(id){
            this.SET_TASK_TYPE_ID(id)
            this.taskListParams.classificationId = id
            this.onTaskList().then()
        },
        /**
         * 跳转流程页面
         * @param obj
         */
        onFlowConfig(obj) {
            this.SET_FLOW_ID(obj.flowId)
            this.$router.push('/workflow/config')
        },
        /**
         * 查看跳转
         * @param row
         */
        onQueryLink(row) {
            this.SET_TASK_ID(row.id)
            this.SET_TASK_LIST(row)
            this.$router.push('/workflow/task/guidance/info')
        },
        /**
         * 查看二维码
         * @param row
         */
        onCode(row){
            this.QRCode = row.id
            this.tasksName = row.tasksName
            this.qrCodeVisible = true
        },
        /**
         * 下载图片
         */
        onDowns(){
            html2canvas(this.$refs.taskGuidanceRef,{
                backgroundColor: null
            }).then((canvas) => {
                let dataURL = canvas.toDataURL("image/png");
                saveCode(this.tasksName,dataURL)
            });
        },
        /**
         * 替换属性名
         * @param data
         */
        onReplaceAttribute(data){
            let item = []
            data.map(list => {
                let newData = {}
                newData.label = list.name;
                newData.value = list.id;
                newData.visible = list.visible;
                if(list.childNode){
                    newData.children = this.onReplaceAttribute(list.childNode)
                }
                item.push(newData)
            })
            return item
        },
        /**
         * 发送当前页
         * @param number
         */
        onSearchPage(number){
            this.taskListParams.currentPage = number
            this.onTaskList().then()
        },
        /**
         * 发送每页显示的条数
         * @param number
         */
        onSearchPageSize(number){
            this.taskListParams.displayNumber = number
            this.onTaskList().then()
        },
        /**
         * 获取任务列表
         */
        async onTaskList() {
            try {
                this.taskLoading = true
                this.taskListArray = {}
                let params = {
                    tasksName:this.taskListParams.tasksName,
                    flowName:this.taskListParams.flowName,
                    status:this.taskListParams.status,
                    tasksType:2,
                    classificationId:this.taskTypeId === '' || this.taskTypeId === undefined?'':this.taskTypeId,
                    size:this.taskListParams.displayNumber,
                    page:this.taskListParams.currentPage
                }
                const { code,data } = await getTaskListService(params)
                if(code === 'SUCCESS'){
                    this.taskListArray = data
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.taskLoading = false
            }catch (error){
                this.taskLoading = false
                console.log(error)
            }
        },
        /**
         * 获取所有设备分类
         */
        async onTaskClassAllService() {
            try {
                let params = {
                    tasksType:2
                }
                const { code,data } = await getTaskClassAllService(params)
                if(code === 'SUCCESS'){
                    for(let i in data){
                        data[i].visible = true

                        if(data[i].childNode.length!==0){
                            for(let j in data[i].childNode){
                                data[i].childNode[j].visible = true
                            }
                        }
                    }
                    this.deviceListArray = this.onReplaceAttribute(data)
                }else{
                    Message.error('请求异常，请联系管理员！')
                }

            }catch (error){
                console.log(error)
            }
        },
    },
    destroyed () {
        window.removeEventListener('resize',this.onTableHeight)
    }
}
export default TaskGuidanceList
